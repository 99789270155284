








import { Component, Vue, Prop } from 'vue-property-decorator';
import { Coordinate, MapSettings } from 'client-website-ts-library/types';
import { Config } from 'client-website-ts-library/services';

const MapBoxMap = () => import('./Providers/MapBoxMap.vue');

@Component({
  components: {
    MapBoxMap,
  },
})
export default class Map extends Vue {
  @Prop()
  public readonly settings!: MapSettings;

  public config = Config.Website.Settings!.MapProvider;

  public SetCenter(center: Coordinate, zoom: number) {
    (this.$refs.map as any).SetCenter(center, zoom);
  }

  public GetZoom(): number {
    return (this.$refs.map as any).GetZoom();
  }
}
